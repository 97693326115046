
/*
import "./style.scss"

import "bootstrap/dist/js/bootstrap.bundle";
*/
import {Leu} from "leu-web-components"

console.log("Registering Leu");


window.Leu = Leu;

export default Leu;
