
import {SmoothScroll} from "./helper/smoothscroll";
import "./components/leu-data-nav";
import "./components/leu-format";

export const Leu = {
    SmoothScroll: SmoothScroll
}


